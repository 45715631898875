// Global Overrides Template Level Only
.ng2-tag-input__text-input {
	width: 100%;
}
tag-input-form {
	width: 100%;
}
.custom-file-label-it:after{
	content:"Scegli un file"
}
.custom-file-label-en:after{
	content:"Choose a file"
}
.custom-file-label-fr:after{
	content:"Choisissez un fichier"
}
.custom-file-label-es:after{
	content:"Elige un archivo"
}
.custom-file-label-de:after{
	content:"Wählen Sie eine Datei"
}
.custom-file-label-sv:after{
	content:"Välj en fil"
}
#importExcelFileModal .modal-header .close {display:none;}
//------- ng-accordion -------
.accordion {
  .card {
    margin: 0;
    background-color: transparent !important;

    &:not(:last-of-type) {
      .card-header {
        border-bottom: 1px solid $border-color;
      }
    }

    &:last-of-type {
      .card-header button {
        padding-bottom: 0;
      }

      .card-body {
        padding-bottom: 0;
		min-height: fit-content;
      }
    }

    .card-header {
      padding: 0;

      button {
        padding: 1.5rem;
        width: 100%;
        text-align: start;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

// for dark and transparent layouts
html body.layout-dark {
  .accordion .card {
    box-shadow: none;
  }

  &:not(.layout-transparent) {
    .accordion .card .card-header {
      border-color: $dl-border-color;

    }
  }

  &.layout-transparent {
    .accordion .card .card-header {
      border-color: $tl-border-color;

      button:not([disabled]) {
        color: map-get($primary-color, lighten-4);
      }
    }
  }
}

.accordion .card .card-header {
	border: 1px solid;
	border-radius: 10px;
	margin-bottom: 15px;
}

//------- horizontal timeline -------
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}



//------- time picker -------
.ngb-tp-input-container button:hover {
  box-shadow: none;
}


//------- Chartist Tooltip -------
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}

company-profile-page .card, 
user-profile-page .card {
	// background-color: #ab8f6985 !important;
	background-color: white !important;
}

a {
	color: map-get($primary-color, "darken-4");
}

body {
	overflow-x: hidden;
}

.text-warning {
	color:  map-get($warning-color, "darken-3") !important;
	font-weight: bold;
}

.btn-link:hover {
	color: #138143;
}

.btn.btn-link i {
	transition-duration: 0.5s;
}
.btn.btn-link:not(.collapsed) i {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
	display: inline-block;
	
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset !important;
}