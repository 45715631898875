.first-row {
	background-color: transparent;
    height: 80px;
    color: #36BF71;
    font-size: large;
    font-weight: bold;
	align-items: center;

	a {
		font-size: small;
	}
}

.italy-flag {
	max-height: 3px;
	overflow: hidden;
	background-color: #36BF71;
}
.green-bg {
	background-color: transparent;
}
.red-bg {
	background-color: transparent;
}

.last-row {
	display: none;
	// background-color: #AB8f69;
	// height: 60px;
	// color: white;
	// font-size: x-large;
	// font-weight: bold;
    // align-items: center;
}

page-footer {
	color: rgba(255, 255, 255, .75);
	padding-top: 40px;
	font-family: "Roboto", sans-serif;
	p {
		font-size: 13px;
		line-height: 2;
	}
	.logo-switch {
		width: 300px;
	}
	.logo-eu {
		// max-width: 300px;
		margin-right: 20px;
	}
	.icon-social {
		width: 30px;
		margin-right: 30px;
	}
	
	.footer-bg {
		background-color: #178aa6;
		padding-top: 40px;
    	padding-bottom: 40px;
	}
	
	@media screen and (max-width: 575px) {
		.header-content{
			padding: 0px 50px;
		}
		
	}
}